export function TimestampToDatelexDateString(timestamp: number): string {
    let date = new Date(timestamp);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return `/Date(${date.getTime()}+${isDST() ? '0100' : '0200'})/`;
}

export function TimestampToDatestanp(timestamp: number): number {
    let date = new Date(timestamp);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date.getTime();
}


export function TimestampToDatelexDateTimeString(timestamp: number): string {
    return `/Date(${timestamp}+${isDST() ? '0100' : '0200'})/`;
}

export function DatalexDateTimeStringToTimestamp(datetimeString: string): number {
    if (!datetimeString) return 0;
    return parseInt(datetimeString.slice(6, 19), 10);
}

function StandardTimezoneOffset() {
    const date = new Date();
    const jan = new Date(date.getFullYear(), 0, 1);
    const jul = new Date(date.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
}

function isDST() {
    const date = new Date();
    return date.getTimezoneOffset() > StandardTimezoneOffset();
}

export function AcceptedGeoLocationTimezone(): number {
    const date = new Date();
    const utc = date.toUTCString();
    const utcTimestamp = +new Date(utc);
    let DST: boolean = date.getTimezoneOffset() > StandardTimezoneOffset();
    const offset = DST ? 2 : 1 * 3600000;
    return utcTimestamp + offset;
}


export function getIsoWithOffset() {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    return (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
}

function pad(num: number) {
    var norm = Math.floor(Math.abs(num));
    return (norm < 10 ? '0' : '') + norm;
};

function padWithZero(number: number) {
    return number < 10 ? '0' + number : number;
}


export function toLocalIsoString(date: Date | number | string = +new Date(), dateOnly: boolean = false, includeMillisecounds: boolean = false) {
    let tempDate = new Date(date)
    var tzo = -tempDate.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-';


    const YYYY = tempDate.getFullYear();
    const MM = pad(tempDate.getMonth() + 1);
    const DD = pad(tempDate.getDate());
    const HH = dateOnly ? "00" : pad(tempDate.getHours());
    const mm = dateOnly ? "00" : pad(tempDate.getMinutes());
    const SS = dateOnly ? "00" : pad(tempDate.getSeconds());
    const sss = includeMillisecounds ? '.' + String(tempDate.getMilliseconds()).padStart(3, '0') : "";

    return `${YYYY}-${MM}-${DD}T${HH}:${mm}:${SS}${sss}${dif}${pad(tzo / 60)}:${pad(tzo % 60)}`
}

export function getJsonDateWithTimeZoneOffset(existingDate?: Date) {
     const currentDate = existingDate || new Date();
     const timezoneOffsetInMinutes = -currentDate.getTimezoneOffset(); // Corrected the sign
   
     // Calculate the offset in the desired format (+02:00)
     const offsetHours = Math.floor(timezoneOffsetInMinutes / 60);
     const offsetMinutes = timezoneOffsetInMinutes % 60;
     const offsetString = (timezoneOffsetInMinutes >= 0 ? '+' : '-') + padWithZero(Math.abs(offsetHours)) + ':' + padWithZero(Math.abs(offsetMinutes));
   
     // Format the date to match the desired output (excluding milliseconds)
     const formattedDate = currentDate.toISOString().replace(/\.\d{3}Z$/, '') + offsetString;
   
     return formattedDate;

  return formattedDate;
  }


export function ViewingDate(date: Date | string, shortDate: boolean = false) {
    let $date;

    if (typeof date === 'string') {
        $date = new Date(date);
    } else {
        $date = date;
    }

    const YYYY = $date.getFullYear();
    const MM = pad($date.getMonth() + 1);
    const DD = pad($date.getDate());
    const HH = pad($date.getHours());
    const mm = pad($date.getMinutes());

    if (shortDate) {
        let yy = `${YYYY}`.slice(-2)
        return {
            date: `${DD}.${MM}.${yy}`,
            time: `${HH}:${mm}`
        }
    }

    return {
        date: `${DD}.${MM}.${YYYY}`,
        time: `${HH}:${mm}`
    }
}